<template>
  <!-- 
    https://csa-rayoapp.web.app/?order_id=111220201200&uuid=123&rating=5&comment=holatest 
    https://csa.rayoapp.com/?order_id=111220201200&uuid=123&rating=5&comment=holatest 
  -->

  <!-- live survey -->

  <!-- 
    http://localhost:8080/?order_id=2548&uuid=123&comment=No+se+a%C3%B1adi%C3%B3+comentarios+(desde+email)&liveSurvey=ok 
  -->
  <!--
     http://csa.rayoapp.com/?order_id=2548&uuid=123&comment=No+se+a%C3%B1adi%C3%B3+comentarios+(desde+email)&liveSurvey=ok 
    -->
  <div v-if="this.result === 1" class="hello">
    <h2>Hemos recibido tu calificación</h2>
    <p>Id de pedido: {{ order_id }}</p>
    <!-- <p>
      <a
        target="_blank"
        :href="'https://seguimiento.thor.rayoapp.com/Consulta/?id=' + order_id"
        >Link al detalle de tu seguimiento</a
      >
    </p> -->

    <div style="text-align: center">
      <img
        v-for="i in parseInt(rating)"
        :key="i"
        width="50"
        alt="Estrellas"
        src="../assets/estrella-activa.png"
      />
    </div>

    <p>
      Has seleccionado {{ rating }} estrellas y agradecemos tu colaboración.
    </p>
    <p>Tu valoración nos ayuda a progresar cada día. ¡Muchas gracias!</p>

    <p>
      <strong>Ya puedes cerrar esta ventana.</strong>
    </p>

    <p>
      <a href="https://rayoapp.com">Rayo Latam 2022</a>
    </p>
    <p>v0.023</p>
  </div>
  <div v-else-if="this.result === 2" class="hello">
    <h2>{{ msg }}</h2>
    <p>Id de pedido: {{ order_id }}</p>
    <!-- <p>
      <a href="">Link al detalle de tu seguimiento</a>
    </p> -->
    <div class="textoCenter">
      <p>Para nosotros tu satisfacción es lo primero.</p>
      <p>
        Para poder seguir mejorando, te pedimos que califiques cómo fue tu
        experiencia de entrega el día de hoy.
      </p>
      <p>
        <input
          type="text"
          :placeholder="placeholderData"
          class="textAreaStyle"
          v-model="comment"
          cols="50"
        />
      </p>
    </div>

    <star-rating
      active-color="#311eee"
      @update:rating="setRating"
      :show-rating="false"
      :v-model="rating"
    ></star-rating>

    <div
      v-if="rating !== undefined"
      style="margin-top: 10px; font-weight: bold"
    >
      Has seleccionado: {{ rating }} estrellas
    </div>
    <div v-else style="margin-top: 10px; font-weight: bold">
      No has seleccionado calificación
    </div>
    <h2 v-if="loading === true" color="#311eee">{{ loadingDots }}</h2>
  </div>
  <div v-else-if="this.result === 0" class="hello">
    <h4 style="color: gray">Cargando...</h4>
  </div>
  <div v-else class="hello">
    <h2>Algo salió mal...</h2>
    <div class="textoCenter">
      <p>
        Al parecer la información que has solicitado no se encuentra disponible
        en este instante.
      </p>
      <p>
        Es posible que estes intentando valorar una orden de entrega que ya fue
        calificada previamente.
      </p>
    </div>
    <strong>Ya puedes cerrar esta ventana.</strong>
  </div>
</template>

<script>
import StarRating from "vue-star-rating";
import axios from "axios";
// import { inheritLeadingComments } from "@babel/types";
// import { useRouter } from 'vue-router'
export default {
  name: "HelloWorld",
  data: function () {
    return {
      loading: false,
      rating: undefined,
      order_id: undefined,
      currentRating: undefined,
      currentSelectedRating: undefined,
      boundRating: 3,
      // comment: "no se añadieron comentarios",
      comment: undefined,
      placeholderData: "Ingresa un comentario (Opcional)",
      uuid: undefined,
      result: 0,
      liveSurvey: undefined,
      loadingDots: "",
    };
  },
  props: {
    msg: String,
  },
  components: {
    StarRating,
  },
  mounted() {
    this.$nextTick(() => {
      this.dots();
    });
    this.order_id = this.$route.query.order_id;
    this.rating = this.$route.query.rating;
    this.uuid = this.$route.query.uuid;
    this.comment = this.$route.query.comment;
    this.liveSurvey = this.$route.query.liveSurvey;
    this.setRating(this.rating);
    // this.user = this.$route.query.user;
    // this.type = this.$route.query.type;
    console.log(this.order_id, " ", this.rating);
    if (
      this.liveSurvey === "ok" &&
      this.order_id !== undefined &&
      this.uuid !== undefined
    ) {
      // está todo ok y va al live survey
      this.result = 2;
    } else if (
      this.order_id !== undefined &&
      this.rating !== undefined &&
      this.uuid !== undefined
    ) {
      // vienen todos los campos. Procedemos a axiospara votar de una vez desde el email
      this.axiosCall();
    } else {
      this.result = 3;
    }
  },
  methods: {
    dots() {
      let self = this;
      var x = 0;
      setInterval(function () {
        var dots = "";
        x++;
        for (var y = 0; y < x % 10; y++) {
          dots += ".";
        }

        // console.log(dots)
        self.loadingDots = dots;
      }, 100);
    },
    setRating: function (rating) {
      console.log(rating);
      this.rating = rating;
      if (this.result === 2) {
        this.axiosCall();
      }
    },
    axiosCall() {
      const that = this;
      that.loading = true;
      axios
        // .put("https://backendservices.rayoapp.com/Task-Groups/csa/",
        .put(
          "https://backendservices.rayoapp.com/Task-Groups/csa/?rayo=08a46637120a404a963395dd86986b4f",
          {
            order_id: this.order_id,
            csa: parseInt(this.rating),
            comment: this.comment == undefined ? "" : this.comment,
          },
          {
            headers: {
              "ris.rayoapp.com": "rayo",
            },
          }
        )
        .then(function (response) {
          console.log(response);
          that.result = 1;
          that.loading = false;
        })
        .catch(function (error) {
          //console.log(error.response.status);
          console.log(error.response);
          // if(error.response.status===304) {
          that.result = 3;
          that.loading = false;
          // }
        });
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.textAreaStyle {
  border-color: rgb(49, 30, 238);
  border-radius: 6px;
  border-style: solid;
  border-width: thin;
  padding: 5px;
  color: rgb(49, 30, 238);
  text-align: center;
  width: 500px;
  height: 56px;
}
.textoCenter {
  margin-left: auto;
  margin-right: auto;
  max-width: 500px;
}
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}

/* .custom-text {
  font-weight: bold;
  font-size: 1.9em;
  border: 1px solid #cfcfcf;
  padding-left: 10px;
  padding-right: 10px;
  border-radius: 5px;
  color: #999;
  background: #fff;
} */
</style>
